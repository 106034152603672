// Packages
import { createSlice } from '@reduxjs/toolkit';
// Redux
import {
  getLaborBurdenCalculationsAsync,
  createLaborBurdenCalculationAsync,
  updateLaborBurdenCalculationAsync,
  getLaborBurdenCalculationsDetailsAsync,
  deleteLaborBurdenCalculationAsync,
} from './laborBurdenCalculationsThunk';
import { clearReducer } from '../auth/authThunk';
// Types
import { IErrorRes } from 'types/appTypes';
import {
  IGetLaborBurdenCalculationDetailsRes,
  IGetLaborBurdenCalculationsRes,
} from 'types/laborBurdenCalculationsTypes';

interface ISliceJLaborCalculationsState {
  laborBurdenCalculations: IGetLaborBurdenCalculationsRes | null;
  laborBurdenCalculationDetails: IGetLaborBurdenCalculationDetailsRes | null;
  error: IErrorRes | null;
  isLoading: boolean;
}

const initialState: ISliceJLaborCalculationsState = {
  laborBurdenCalculations: null,
  laborBurdenCalculationDetails: null,
  error: null,
  isLoading: false,
};

const laborBurdenCalculationSlice = createSlice({
  name: 'laborBurdenCalculations',
  initialState,
  reducers: {
    clearErrors: state => {
      state.error = null;
    },
  },
  extraReducers: builder => {
    //* GET LABOR BURDEN CALCULATIONS ASYNC THUNK
    builder.addCase(getLaborBurdenCalculationsAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getLaborBurdenCalculationsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.laborBurdenCalculations = payload;
    });
    builder.addCase(getLaborBurdenCalculationsAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* CREATE LABOR_BURDEN_CALCULATION ASYNC THUNK
    builder.addCase(createLaborBurdenCalculationAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(createLaborBurdenCalculationAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(createLaborBurdenCalculationAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* GET LABOR_BURDEN_CALCULATION_DETAILS ASYNC THUNK
    builder.addCase(getLaborBurdenCalculationsDetailsAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getLaborBurdenCalculationsDetailsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.laborBurdenCalculationDetails = payload;
    });
    builder.addCase(getLaborBurdenCalculationsDetailsAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* UPDATE LABOR_BURDEN_CALCULATION ASYNC THUNK
    builder.addCase(updateLaborBurdenCalculationAsync.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(updateLaborBurdenCalculationAsync.fulfilled, state => {
      state.isLoading = false;
      if (state.error) {
        state.error = null;
      }
    });
    builder.addCase(updateLaborBurdenCalculationAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* DELETE LABOR_BURDEN_CALCULATION ASYNC THUNK
    builder.addCase(deleteLaborBurdenCalculationAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(deleteLaborBurdenCalculationAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(deleteLaborBurdenCalculationAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* CLEAR REDUCER AFTER SIGN OUT
    builder.addCase(clearReducer, () => initialState);
  },
});

export default laborBurdenCalculationSlice;
