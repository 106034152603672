// Modules
import xhr from 'modules/xhr';
// API
import { ENDPOINTS } from '../api.config';
// Interfaces and types
import { TGetIndustriesRes } from 'types/industryTypes';

export const getIndustries = ({ lang }: { lang?: string }) => {
  return xhr.get<TGetIndustriesRes, TGetIndustriesRes>(`${ENDPOINTS.industries}`, {
    headers: {
      'Accept-Language': `${lang},en`,
    },
  });
};
