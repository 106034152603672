// Packages
import axios from 'axios';
// Modules
import xhr from 'modules/xhr';
// API
import { ENDPOINTS } from '../api.config';
import { API_URL } from 'api/api.config';
// Storage
import webStorage from 'modules/storage';
// Interfaces and types
import {
  ICompanyAccountNameRes,
  ICompanyLogoImgUploadRes,
  ICompanyLogoImgDeleteRes,
  ICompanyLogoImgUploadBody,
  IGetSettingsRes,
  IUpdateSettingsBody,
  IUpdateSettingsRes,
  IGetCompaniesRes,
  ICreateCompanyBody,
  ICreateCompanyRes,
  IGetCompanyRes,
  IUpdateCompanyBodyWithParam,
  IUpdateCompanyRes,
  IDeleteCompanyRes,
  TGetCompanyPayrollRes,
  IGetCompaniesStatusesRes,
  ICreateCompanyStatusBody,
  ICreateCompanyStatusRes,
} from 'types/companyTypes';
import { IResMessage, TQueryParams } from 'types/appTypes';
// I18n
import { getCurrentLanguage } from 'i18n';

export const checkCompanyAccountStatus = (accountName: string) => {
  return xhr.get<ICompanyAccountNameRes, ICompanyAccountNameRes>(
    `${ENDPOINTS.companies}/${accountName}/info`,
  );
};

export const uploadCompanyImg = (formData: ICompanyLogoImgUploadBody) => {
  return xhr.put<ICompanyLogoImgUploadRes, ICompanyLogoImgUploadRes>(
    `${ENDPOINTS.companyImg}`,
    formData,
  );
};

export const deleteCompanyImg = () => {
  return xhr.delete<ICompanyLogoImgDeleteRes, ICompanyLogoImgDeleteRes>(
    `${ENDPOINTS.companyImg}`,
  );
};

export const getCompanySettings = () => {
  return xhr.get<IGetSettingsRes, IGetSettingsRes>(`${ENDPOINTS.companySettings}`);
};

export const changeCompanySettings = (body: IUpdateSettingsBody) => {
  return xhr.put<IUpdateSettingsRes, IUpdateSettingsRes>(`${ENDPOINTS.companySettings}`, body);
};

export const getCompanyPayroll = () => {
  return xhr.get<TGetCompanyPayrollRes, TGetCompanyPayrollRes>(`${ENDPOINTS.companyPayroll}`);
};

export const getCompanies = (params: TQueryParams) => {
  return xhr.get<IGetCompaniesRes, IGetCompaniesRes>(`${ENDPOINTS.companies}`, { params });
};

export const createCompany = (body: ICreateCompanyBody) => {
  return xhr.post<ICreateCompanyRes, ICreateCompanyRes>(`${ENDPOINTS.companies}`, body);
};

export const updateCompany = ({ accountName, body }: IUpdateCompanyBodyWithParam) => {
  return xhr.patch<IUpdateCompanyRes, IUpdateCompanyRes>(
    `${ENDPOINTS.companyBusiness}/${accountName}`,
    body,
  );
};

export const deleteCompany = (accountName: string) => {
  return xhr.delete<IDeleteCompanyRes, IDeleteCompanyRes>(
    `${ENDPOINTS.companyBusiness}/${accountName}`,
  );
};

export const getCompany = (accountName: string) => {
  return xhr.get<IGetCompanyRes, IGetCompanyRes>(`${ENDPOINTS.companies}/${accountName}`);
};

// Company report;
export const getCompanyReport = (params?: { companyId: number }) => {
  const storageData = webStorage.getData();
  const language = getCurrentLanguage();

  return axios.get<Blob>(`${API_URL}${ENDPOINTS.companyReport}`, {
    headers: {
      Authorization: `Bearer ${storageData.accessToken}`,
      Accept: 'application/json',
      'Accept-Language': language,
      'Content-Type': 'application/json',
    },
    responseType: 'blob',
    params,
  });
};

export const getClientsStatuses = (params: TQueryParams) => {
  return xhr.get<IGetCompaniesStatusesRes, IGetCompaniesStatusesRes>(
    `${ENDPOINTS.clientStatus}`,
    {
      params,
    },
  );
};

export const createClientStatus = (body: ICreateCompanyStatusBody) => {
  return xhr.post<ICreateCompanyStatusRes, ICreateCompanyStatusRes>(
    `${ENDPOINTS.clientStatus}`,
    body,
  );
};

export const deleteClientStatus = (id: number) => {
  return xhr.delete<IResMessage, IResMessage>(`${ENDPOINTS.clientStatus}/${id}`);
};
