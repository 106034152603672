// Modules
import xhr from 'modules/xhr';
// API
import { ENDPOINTS } from '../api.config';
// Types
import { IGetLaborBurdenLabels, IUpdateLaborBurdenLabels } from 'types/laborBurdenLabelsTypes';
import { IResMessage } from 'types/appTypes';

export const getLaborBurdenLabels = () => {
  return xhr.get<IGetLaborBurdenLabels, IGetLaborBurdenLabels>(
    `${ENDPOINTS.laborBurdenLabels}`,
  );
};

export const updateLaborBurdenLabels = (body: IUpdateLaborBurdenLabels) => {
  return xhr.put<IResMessage, IResMessage>(`${ENDPOINTS.laborBurdenLabels}`, body);
};
