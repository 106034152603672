// Modules
import xhr from 'modules/xhr';
// API
import { ENDPOINTS } from '../api.config';
// Interfaces and types
import {
  IGetLaborBurdenCalculationsRes,
  ICreateLaborBurdenCalculationBody,
  ICreateLaborBurdenCalculationRes,
  IGetLaborBurdenCalculationDetailsRes,
  IUpdateLaborBurdenCalculationBodyWithParam,
} from 'types/laborBurdenCalculationsTypes';
import { IResMessage, TQueryParams } from 'types/appTypes';

export const getLaborBurdenCalculations = (params: TQueryParams) => {
  return xhr.get<IGetLaborBurdenCalculationsRes, IGetLaborBurdenCalculationsRes>(
    `${ENDPOINTS.laborBurdenCalculations}`,
    {
      params,
    },
  );
};

export const createLaborBurdenCalculation = (body: ICreateLaborBurdenCalculationBody) => {
  return xhr.post<ICreateLaborBurdenCalculationRes, ICreateLaborBurdenCalculationRes>(
    `${ENDPOINTS.laborBurdenCalculations}`,
    body,
  );
};

export const getLaborBurdenCalculationDetails = (id: number) => {
  return xhr.get<IGetLaborBurdenCalculationDetailsRes, IGetLaborBurdenCalculationDetailsRes>(
    `${ENDPOINTS.laborBurdenCalculations}/${id}`,
  );
};

export const updateLaborBurdenCalculation = ({
  body,
  id,
}: IUpdateLaborBurdenCalculationBodyWithParam) => {
  return xhr.put<IResMessage, IResMessage>(`${ENDPOINTS.laborBurdenCalculations}/${id}`, body);
};

export const deleteLaborBurdenCalculation = (id: number) => {
  return xhr.delete<IResMessage, IResMessage>(`${ENDPOINTS.laborBurdenCalculations}/${id}`);
};
