// Packages
import { createSlice } from '@reduxjs/toolkit';
// Redux
import {
  getLaborBurdenLabelsAsync,
  updateLaborBurdenLabelsAsync,
} from './laborBurdenLabelsThunk';
import { clearReducer } from '../auth/authThunk';
// Interfaces and types
import { IErrorRes } from 'types/appTypes';
import { TSliceReducer, TActionType } from 'redux/store';
import { IGetLaborBurdenLabels } from 'types/laborBurdenLabelsTypes';

type TAction<T extends TActionType> =
  | typeof getLaborBurdenLabelsAsync[T]
  | typeof updateLaborBurdenLabelsAsync[T];

const handleLoadingReducer: TSliceReducer<IState, TAction<'pending'>> = state => {
  state.isLoading = true;
  state.error = null;
};

const handleEmptyFulfilledReducer: TSliceReducer<IState, TAction<'fulfilled'>> = state => {
  state.isLoading = false;
};

const handleErrorReducer: TSliceReducer<IState, TAction<'rejected'>> = (state, action) => {
  state.isLoading = false;
  state.error = action.payload || null;
};

interface IState {
  laborBurdenLabels: IGetLaborBurdenLabels | null;
  error: IErrorRes | null;
  isLoading: boolean;
}

const initialState: IState = {
  laborBurdenLabels: null,
  error: null,
  isLoading: false,
};

const laborBurdenLabels = createSlice({
  name: 'laborBurnedLabels',
  initialState,
  reducers: {},
  extraReducers: builder => {
    //* GET RATES ASYNC THUNK
    builder.addCase(getLaborBurdenLabelsAsync.pending, handleLoadingReducer);
    builder.addCase(getLaborBurdenLabelsAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.laborBurdenLabels = payload;
    });
    builder.addCase(getLaborBurdenLabelsAsync.rejected, handleErrorReducer);

    //* UPDATE RATES ASYNC THUNK
    builder.addCase(updateLaborBurdenLabelsAsync.pending, handleLoadingReducer);
    builder.addCase(updateLaborBurdenLabelsAsync.fulfilled, handleEmptyFulfilledReducer);
    builder.addCase(updateLaborBurdenLabelsAsync.rejected, handleErrorReducer);

    //* CLEAR REDUCER AFTER SIGN OUT
    builder.addCase(clearReducer, () => initialState);
  },
});

export default laborBurdenLabels;
