// Packages
import { createAsyncThunk } from '@reduxjs/toolkit';
// API
import { LaborBurdenLabelsApi } from 'api/endpoints';
// Interfaces and types
import { IGetLaborBurdenLabels, IUpdateLaborBurdenLabels } from 'types/laborBurdenLabelsTypes';
import { IErrorRes, IResMessage } from 'types/appTypes';

const getLaborBurdenLabelsAsync = createAsyncThunk<
  IGetLaborBurdenLabels,
  void,
  { rejectValue: IErrorRes }
>('laborBurdenLabels/getLaborBurdenLabels', async (_: void, { rejectWithValue }) => {
  try {
    return await LaborBurdenLabelsApi.getLaborBurdenLabels();
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const updateLaborBurdenLabelsAsync = createAsyncThunk<
  IResMessage,
  IUpdateLaborBurdenLabels,
  { rejectValue: IErrorRes }
>(
  'laborBurdenLabels/updateLaborBurdenLabels',
  async (cred: IUpdateLaborBurdenLabels, { rejectWithValue }) => {
    try {
      return await LaborBurdenLabelsApi.updateLaborBurdenLabels(cred);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

export { getLaborBurdenLabelsAsync, updateLaborBurdenLabelsAsync };
