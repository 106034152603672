// Packages
import { createAsyncThunk } from '@reduxjs/toolkit';
// API
import { LaborBurdenCalculationsApi } from 'api/endpoints';
// Interfaces and types
import {
  ICreateLaborBurdenCalculationBody,
  IGetLaborBurdenCalculationsRes,
  ICreateLaborBurdenCalculationRes,
  IGetLaborBurdenCalculationDetailsRes,
  IUpdateLaborBurdenCalculationBodyWithParam,
} from 'types/laborBurdenCalculationsTypes';
import { IErrorRes, IResMessage, TQueryParams } from 'types/appTypes';

const getLaborBurdenCalculationsAsync = createAsyncThunk<
  IGetLaborBurdenCalculationsRes,
  TQueryParams,
  { rejectValue: IErrorRes }
>(
  'laborBurdenCalculations/getLaborBurdenCalculations',
  async (params: TQueryParams, { rejectWithValue }) => {
    try {
      return await LaborBurdenCalculationsApi.getLaborBurdenCalculations(params);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const createLaborBurdenCalculationAsync = createAsyncThunk<
  ICreateLaborBurdenCalculationRes,
  ICreateLaborBurdenCalculationBody,
  { rejectValue: IErrorRes }
>(
  'laborBurdenCalculations/createLaborBurdenCalculation',
  async (body: ICreateLaborBurdenCalculationBody, { rejectWithValue }) => {
    try {
      return await LaborBurdenCalculationsApi.createLaborBurdenCalculation(body);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const getLaborBurdenCalculationsDetailsAsync = createAsyncThunk<
  IGetLaborBurdenCalculationDetailsRes,
  number,
  { rejectValue: IErrorRes }
>(
  'laborBurdenCalculations/getLaborBurdenCalculationDetails',
  async (params: number, { rejectWithValue }) => {
    try {
      return await LaborBurdenCalculationsApi.getLaborBurdenCalculationDetails(params);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const updateLaborBurdenCalculationAsync = createAsyncThunk<
  IResMessage,
  IUpdateLaborBurdenCalculationBodyWithParam,
  { rejectValue: IErrorRes }
>(
  'laborBurdenCalculations/updateLaborBurdenCalculation',
  async (body: IUpdateLaborBurdenCalculationBodyWithParam, { rejectWithValue }) => {
    try {
      return await LaborBurdenCalculationsApi.updateLaborBurdenCalculation(body);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const deleteLaborBurdenCalculationAsync = createAsyncThunk<
  IResMessage,
  number,
  { rejectValue: IErrorRes }
>(
  'laborBurdenCalculations/deleteLaborBurdenCalculation',
  async (id: number, { rejectWithValue }) => {
    try {
      return await LaborBurdenCalculationsApi.deleteLaborBurdenCalculation(id);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

export {
  getLaborBurdenCalculationsDetailsAsync,
  getLaborBurdenCalculationsAsync,
  createLaborBurdenCalculationAsync,
  updateLaborBurdenCalculationAsync,
  deleteLaborBurdenCalculationAsync,
};
